import React, { useMemo } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import queryString from "query-string"
import textStyles from "../styles/text.module.scss"
import classNames from "classnames"
import { buttonHarness, buttonHarnessNarrow, button, checkoutButton } from '../styles/button.module.scss'
import { Link } from "gatsby"

const EndedPage = ({ location }) => {
    const { client_reference_id: clientReferenceId, sku_id: skuId } = useMemo(() => {
        return queryString.parse(location.search);
    });

    return (
        <Layout>
            <SEO title="Your Piing Party Has Ended!" />
            <div className={classNames(textStyles.title, textStyles.big)}>
              Oh no, your <span className={textStyles.highlight}>party's over!</span> 😢
            </div>
            <div className={classNames(buttonHarness, buttonHarnessNarrow)}>
              <Link to='/#games' className={classNames(button, checkoutButton)}>Start another</Link>
            </div>
            <div className={textStyles.body}>
                Just hit the button above to get partying again.<br />Find out more in our <Link to={'/faq'}>FAQs</Link>
            </div>
        </Layout>
    )
}

export default EndedPage